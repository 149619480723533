<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="pr-4">
          <h5 class="text-18 pb-1">
            <router-link :to="{ name: 'referrals', params: { app: 'fd1' } }" class="text-decoration-underline">
              {{ t("referral_system") }}
            </router-link>
          </h5>
        </div>
        <v-img :src="img" :max-width="48" :max-height="48" />
      </div>

      <div class="grey--text text--darken-3 text-15" v-html="t('referral_system_text')"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import Img from "@/assets/images/brainstorming.svg";

export default {
  computed: {
    img() {
      return Img;
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
