<template>
  <div class="d-flex justify-space-between">
    <div class="pr-4" style="flex-grow: 1">
      <h5 class="text-18">{{ t(apptype + "_partners_program_settings") }}</h5>

      <span class="text-body-2">
        {{ t("your_current_plan") }}
      </span>
      <span class="text-body-2 black--text">
        {{ currentPlanLabel }}
      </span>

      <br />

      <span class="text-body-2">
        {{ t("rate_plan_from_next_day") }}
      </span>

      <span class="text-body-2 black--text">
        {{ nextDayPlanLabel }}
      </span>

      <br />

      <div v-if="tariffChangeBlocked" class="mt-2 mb-2">
        Your rate plan has been changed <strong>{{ tariffChangedDate }}</strong
        >.
      </div>

      <div class="d-flex">
        <v-select
          v-model="selectedTariff"
          :items="tariffsOptionsForSelect"
          :disabled="tariffChangeBlocked"
          filled
          dense
          class="mt-2"
          style="max-width: 350px;"
          :hint="t('change_rate_plan_hint')"
          persistent-hint
        ></v-select>

        <v-btn
          color="primary"
          class="ml-2 mt-3 blue darken-1"
          :disabled="!selectedTariff"
          :loading="changingTariff"
          @click="changeTariff"
        >
          {{ t("change_rate_plan") }}
        </v-btn>
      </div>
    </div>

    <v-img :src="settingsImg" :max-width="64" :max-height="64" />
  </div>
</template>

<script>
import SettingsImg from "@/assets/images/settings2.svg";

export default {
  props: {
    allTariffs: {
      type: Object,
      default() {
        return {};
      }
    },
    actualTariffs: {
      type: Object,
      default() {
        return {};
      }
    },
    currentPlanId: Number,
    nextDayPlanId: Number,
    tariffChangeBlocked: Boolean,
    tariffChangedDate: String,
    loadTariffData: Function
  },

  data() {
    return {
      changingTariff: false,
      selectedTariff: null
    };
  },

  computed: {
    apptype() {
      return this.$route.params.app;
    },

    settingsImg() {
      return SettingsImg;
    },

    tariffsOptionsForSelect() {
      return Object.entries(this.actualTariffs).map(item => {
        return {
          value: item[0],
          text: item[1]
        };
      });
    },

    currentPlanLabel() {
      return this.currentPlanId ? this.allTariffs[this.currentPlanId] : null;
    },

    nextDayPlanLabel() {
      return this.nextDayPlanId ? this.allTariffs[this.nextDayPlanId] : null;
    }
  },

  methods: {
    changeTariff() {
      this.changingTariff = true;

      this.axios
        .post("tariff/" + this.apptype + "/change", {
          new_tariff: this.selectedTariff
        })
        .then(() => {
          this.loadTariffData();
          this.changingTariff = false;
          this.selectedTariff = null;
        })
        .catch(() => {
          this.changingTariff = false;
        });
    },

    t(key, params) {
      return this.$t("pages.rates." + key, params);
    }
  }
};
</script>
