<template>
  <v-card :elevation="0">
    <v-card-text>
      <program-settings
        v-bind="{
          allTariffs,
          actualTariffs,
          currentPlanId,
          nextDayPlanId,
          tariffChangeBlocked,
          tariffChangedDate,
          loadTariffData
        }"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ProgramSettings from "./ProgramSettings";

export default {
  components: { ProgramSettings },

  data() {
    return {
      allTariffs: {},
      actualTariffs: {},
      currentPlanId: null,
      nextDayPlanId: null,
      tariffChangeBlocked: false,
      tariffChangedDate: null
    };
  },

  mounted() {
    this.loadTariffData();
  },

  methods: {
    loadTariffData() {
      this.axios.get("tariff/fd1/data").then(response => {
        this.allTariffs = response.data.all_tariffs;
        this.actualTariffs = response.data.actual_tariffs;
        this.currentPlanId = response.data.user_current_plan;
        this.nextDayPlanId = response.data.user_next_day_plan;
        this.tariffChangeBlocked = response.data.changing_tariff_blocked;
        this.tariffChangedDate = response.data.tariff_changed_date;
      });
    },

    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
