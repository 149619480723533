<template>
  <v-card clas="pb-6" :elevation="0">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="pr-4">
          <h5 class="text-18 pb-5">{{ t("hitfile_rate_plans") }}</h5>
        </div>
      </div>
      <div>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <span class="d-block mb-1 text-body-1 blue--text text--darken-1">
                  <strong>{{ t("premium_sale") }}</strong>
                </span>

                <span class="text-body-2">
                  {{ t("rates_for_sale_of_premium_accounts_hitfile") }}
                </span>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="text-body-1">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>{{ t("country") }}</th>
                        <th>{{ t("percent_of_sale") }}</th>
                        <th>{{ t("percent_of_rebill") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ t("all_countries") }}</td>
                        <td>60%</td>
                        <td>40%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
