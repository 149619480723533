<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="pr-4">
          <h5 class="text-18 pb-1">
            {{ t("bonus_program") }}
            <router-link :to="{ name: 'gold', params: { app: 'fd1' } }" class="amber--text text-decoration-underline"
              >"{{ t("gold_partners") }}"</router-link
            >
          </h5>
        </div>
        <v-img :src="medalImg" :max-width="48" :max-height="48" />
      </div>

      <div class="grey--text text--darken-3 text-15">
        {{ t("increased_payments_and_extra_bonuses") }}: <br />
        - <span v-html="t('increased_payments_and_extra_bonuses_1')" /><br />
        - <span v-html="t('increased_payments_and_extra_bonuses_2')" /><br />
        - <span v-html="t('increased_payments_and_extra_bonuses_3')" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MedalImg from "@/assets/images/medal.svg";

export default {
  computed: {
    medalImg() {
      return MedalImg;
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
