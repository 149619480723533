<template>
  <v-card :elevation="0">
    <v-card-text>
      <program-settings
        v-bind="{
          allTariffs,
          actualTariffs,
          currentPlanId,
          nextDayPlanId,
          tariffChangeBlocked,
          tariffChangedDate,
          loadTariffData
        }"
      />

      <div class="mt-4">
        <h6>{{ t("price_of_premium_account_for_users") }}</h6>

        <div class="caption">
          {{ t("price_of_premium_account_for_users_subtitle") }}
        </div>

        <div>
          <v-radio-group v-model="tariffType" :error-messages="ratesErrors">
            <div class="d-flex">
              <div style="max-width: 300px;">
                <v-radio value="standart" :label="t('standard_rates')" class="mb-0" />

                <div tabindex="-1" class="ml-4 pt-0 mt-0 v-list-item theme--light">
                  <div class="v-list-item__content mt-0 text-gray-700">
                    <div v-for="rate in tariffRatesByType(['standart'])" class="v-list-item__title">
                      ${{ rate.tariff_amount }} - {{ rate.tariff_title }}
                    </div>
                  </div>
                </div>
              </div>

              <div style="max-width: 300px;">
                <v-radio value="expensive" :label="t('expensive_rates')" class="mb-0" />

                <div tabindex="-1" class="ml-4 pt-0 mt-0  v-list-item theme--light">
                  <div class="v-list-item__content mt-0 text-gray-700">
                    <div v-for="rate in tariffRatesByType(['expensive'])" class="v-list-item__title">
                      ${{ rate.tariff_amount }} - {{ rate.tariff_title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="caption font-italic ml-8">
                {{ t("make_your_own_price_rates_variation", { value: 5 }) }}
              </div>
            </div>

            <div class="d-flex">
              <div style="max-width: 300px;">
                <v-radio value="own" :label="t('my_price')" class="mb-0 mt-2" />

                <div tabindex="-1" class="ml-4 pt-0 mt-0  v-list-item theme--light">
                  <div
                    :class="[tariffType !== 'own' ? 'text-gray-400' : 'text-gray-700']"
                    class="v-list-item__content mt-0"
                  >
                    <div
                      v-for="rate in tariffRatesByType(['standart', 'expensive'], true)"
                      :key="rate.tariff_id"
                      class="v-list-item__title"
                    >
                      <div class="v-list-item__title">
                        <label>
                          <input v-model="userTariffRates" :value="rate.tariff_id" type="checkbox" />
                          ${{ rate.tariff_amount }} - {{ rate.tariff_title }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-radio-group>

          <v-btn :loading="savingTariffRates" small color="primary" class="mt-3" @click="saveTariffRates">
            {{ $t("common.save") }}
          </v-btn>
        </div>
      </div>

      <div class="mt-6">
        <h6>{{ t("free_download_options") }}</h6>

        <div v-if="freeDownloadOptionsDisabled" class="caption">
          {{ t("free_download_options_available_only_on_pps_rate_plan") }}
        </div>

        <div>
          <v-radio-group v-model="globalFreeDownload">
            <div>
              <v-radio
                value="free"
                :label="t('allow_a_free_download_of_all_files')"
                class="mb-0"
                :disabled="freeDownloadOptionsDisabled"
              />
              <div class="caption ml-8">
                {{ t("allow_a_free_download_of_all_files_hint") }}
              </div>
            </div>

            <div class="mt-2">
              <v-radio
                value="pay"
                :label="t('disallow_a_free_download_of_all_files')"
                class="mb-0"
                :disabled="freeDownloadOptionsDisabled"
              />
              <div class="caption ml-8">
                {{ t("disallow_a_free_download_of_all_files_hint") }}
              </div>
            </div>

            <div class="mt-2">
              <v-radio
                value="user"
                :label="t('use_individual_settings_of_files_and_folders')"
                class="mb-0"
                :disabled="freeDownloadOptionsDisabled"
              />
              <div class="caption ml-8">
                {{ t("use_individual_settings_of_files_and_folders_hint") }}
              </div>

              <div class="caption ml-8 mt-3">
                {{ t("inbox_free_download_label") }}
              </div>

              <v-select
                v-model="inboxFreeDownload"
                :items="[
                  { text: t('allow_free_download'), value: true },
                  { text: t('disallow_free_download'), value: false }
                ]"
                single-line
                class="mt-2 ml-8 v-select--max-content-fit"
                :disabled="globalFreeDownload !== 'user' || freeDownloadOptionsDisabled"
                dense
              ></v-select>
            </div>
          </v-radio-group>
        </div>

        <v-btn :loading="savingGlobalFreeDownload" small color="primary" @click="saveGlobalFreeDownload">
          {{ $t("common.save") }}
        </v-btn>
      </div>

      <div class="mt-8">
        <h6>{{ t("the_maximum_file_size_available_for_free_downloads") }}</h6>

        <div class="d-flex">
          <div class="caption mt-3">
            {{ t("the_maximum_file_size_available_for_free_downloads_label") }}
          </div>

          <v-select
            v-model="freeDownloadSize"
            :items="freeDownloadSizeOptionsForSelect"
            text
            single-line
            class="ml-2 mt-2 pt-0"
            style="max-width: 100px;"
            dense
          ></v-select>
        </div>

        <v-btn small color="primary" :loading="savingFreeDownloadSize" @click="saveFreeDownloadSize">
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ProgramSettings from "./ProgramSettings";

export default {
  components: { ProgramSettings },

  data() {
    return {
      allTariffs: {},
      actualTariffs: {},
      currentPlanId: null,
      nextDayPlanId: null,
      tariffChangeBlocked: false,
      tariffChangedDate: null,

      savingTariffRates: false,
      tariffType: null,
      tariffRates: [],
      userTariffRates: [],
      ratesErrors: [],

      savingFreeDownloadSize: false,
      freeDownloadSizeOptions: [],
      freeDownloadSize: null,

      savingGlobalFreeDownload: false,
      globalFreeDownload: null,
      inboxFreeDownload: true
    };
  },

  computed: {
    freeDownloadSizeOptionsForSelect() {
      return this.freeDownloadSizeOptions.map(x => {
        let item = {
          text: isNaN(x.size_in_mb) ? this.t(`free_download.${x.size_in_mb}`) : x.size_in_mb + " MB",
          value: x.value
        };

        return item;
      });
    },

    freeDownloadOptionsDisabled() {
      return this.currentPlanId === this.payPerDownloadsPlanId;
    },

    payPerDownloadsPlanId() {
      return 11;
    }
  },

  mounted() {
    this.loadTariffData();
  },

  methods: {
    loadTariffData() {
      this.axios.get("tariff/fd2/data").then(response => {
        this.allTariffs = response.data.all_tariffs;
        this.actualTariffs = response.data.actual_tariffs;
        this.currentPlanId = response.data.user_current_plan;
        this.nextDayPlanId = response.data.user_next_day_plan;
        this.tariffChangeBlocked = response.data.changing_tariff_blocked;
        this.tariffChangedDate = response.data.tariff_changed_date;

        this.tariffType = response.data.tariff_type;
        this.tariffRates = response.data.tariff_rates;
        this.userTariffRates = response.data.user_tariff_rates;

        this.freeDownloadSizeOptions = response.data.free_download_size_options;
        this.freeDownloadSize = response.data.free_download_size;

        this.globalFreeDownload = response.data.global_free_download;
        this.inboxFreeDownload = response.data.inbox_free_download;
      });
    },

    saveFreeDownloadSize() {
      this.savingFreeDownloadSize = true;

      this.axios
        .post("tariff/fd2/free-download-size", {
          free_download_size: this.freeDownloadSize
        })
        .then(() => {
          this.savingFreeDownloadSize = false;
        });
    },

    saveGlobalFreeDownload() {
      this.savingGlobalFreeDownload = true;

      this.axios
        .post("tariff/fd2/global-free-download", {
          global_free_download: this.globalFreeDownload,
          inbox_free_download: this.inboxFreeDownload
        })
        .then(() => {
          this.savingGlobalFreeDownload = false;
        });
    },

    saveTariffRates() {
      this.savingTariffRates = true;

      this.axios
        .post("tariff/fd2/tariff-rates", {
          tariff_type: this.tariffType,
          tariff_rates: this.userTariffRates
        })
        .then(() => {
          this.savingTariffRates = false;
          this.ratesErrors = [];
        })
        .catch(error => {
          this.ratesErrors = [error.response.data.message ?? this.$t("fm.actions.prolong.try_again")];
          this.ratesErrors = error.response.data.errors.tariff_type ?? this.tariffRates;
          this.ratesErrors = error.response.data.errors.tariff_rates ?? this.tariffRates;
          this.savingTariffRates = false;
        });
    },

    tariffRatesByType(types, includeSpecial = false) {
      let rates = [];

      for (let index in this.tariffRates) {
        if (types.includes(this.tariffRates[index].tariff_type)) {
          if (! includeSpecial && this.tariffRates[index].tariff_special) {
            continue;
          }
          rates.push(this.tariffRates[index]);
        }
      }

      return rates.sort((rate1, rate2) => rate1.tariff_position - rate2.tariff_position);
    },

    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
