var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":0}},[_c('v-card-text',[_c('program-settings',_vm._b({},'program-settings',{
        allTariffs: _vm.allTariffs,
        actualTariffs: _vm.actualTariffs,
        currentPlanId: _vm.currentPlanId,
        nextDayPlanId: _vm.nextDayPlanId,
        tariffChangeBlocked: _vm.tariffChangeBlocked,
        tariffChangedDate: _vm.tariffChangedDate,
        loadTariffData: _vm.loadTariffData
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }