<template>
  <v-card clas="pb-6" :elevation="0">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="pr-4">
          <h5 class="text-18 pb-5">{{ t("turbobit_rate_plans") }}</h5>
        </div>
      </div>
      <div>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <span class="d-block mb-1 text-body-1 blue--text text--darken-1">
                  <strong>{{ t("premium_sale") }}</strong>
                </span>

                <span class="text-body-2">
                  {{ t("rates_for_sale_of_premium_accounts") }}
                </span>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="text-body-1">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>{{ t("country") }}</th>
                        <th>{{ t("percent_of_sale") }}</th>
                        <th>{{ t("percent_of_rebill") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ t("all_countries") }}</td>
                        <td>60%</td>
                        <td>40%</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <span class="d-block mb-1 text-body-1 blue--text text--darken-1">
                  <strong>{{ t("mixed") }}</strong>
                </span>

                <span class="text-body-2">
                  {{ t("mixed_hint") }}
                </span>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="text-body-2 mb-2">
                <b>{{ t("rates") }}:</b>
              </div>

              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>{{ t("group") }}</th>
                      <th>{{ t("for_1000_downloads") }}*</th>
                      <th>{{ t("percent_of_sale") }}</th>
                      <th>{{ t("percent_of_rebill") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="rate in mixedRates">
                      <td>{{ rate[0] }}</td>
                      <td>{{ rate[1] }}</td>
                      <td>{{ rate[2] }}</td>
                      <td>{{ rate[3] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="caption">* {{ t("for_1000_downloads_hint") }}</div>

              <div class="text-body-2 mt-2 mb-1">
                <b>{{ t("group_and_countries") }}:</b>
              </div>

              <div class="table-legend">
                <b>А:</b> {{ t("mixed_countries_a") }}<br />
                <b>B:</b> {{ t("mixed_countries_b") }}<br />
                <b>C:</b> {{ t("mixed_countries_c") }}<br />
                <b>D:</b> {{ t("mixed_countries_d") }}<br />
                <b>E:</b> {{ t("mixed_countries_e") }}<br />
                <b>F:</b> {{ t("mixed_countries_f") }}<br />
                <b>G:</b> {{ t("mixed_countries_g") }}
              </div>

              <div class="text-body-2 mt-2">
                <b>{{ t("rate_chart_for_russia_and_ukraine") }}</b>
              </div>

              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>{{ t("group") }}</th>
                      <th>{{ t("for_1000_downloads") }}*</th>
                      <th>{{ t("percent_of_sale") }}</th>
                      <th>{{ t("percent_of_rebill") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="rate in mixedRatesForRussiaAndUkraine">
                      <td>{{ rate[0] }}</td>
                      <td>{{ rate[1] }}</td>
                      <td>{{ rate[2] }}</td>
                      <td>{{ rate[3] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="caption">
                <i>
                  {{ t("rate_chart_for_russia_and_ukraine_hint_1") }}
                  <br />
                  {{ t("rate_chart_for_russia_and_ukraine_hint_2") }}
                </i>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <span class="d-block mb-1 text-body-1 blue--text text--darken-1">
                  <strong>{{ t("all_inclusive") }}</strong>
                </span>

                <span class="text-body-2">
                  {{ t("all_inclusive_hint") }}
                </span>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="text-body-2 mb-2">
                <b>{{ t("rate_for_downloading") }}:</b>
              </div>

              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>{{ t("size_country") }}</th>
                      <th>A</th>
                      <th>B</th>
                      <th>C</th>
                      <th>D</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="rate in inclusiveRates">
                      <td>{{ rate[0] }}</td>
                      <td>
                        <strong>{{ rate[1] }}</strong>
                      </td>
                      <td>{{ rate[2] }}</td>
                      <td>{{ rate[3] }}</td>
                      <td>{{ rate[4] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="caption">* {{ t("rate_for_downloading_hint") }}</div>

              <div class="text-body-2 mt-2 mb-1">
                <b>{{ t("group_and_countries") }}:</b>
              </div>

              <div class="table-legend">
                <b>А:</b> {{ t("all_inclusive_countries_a") }}<br />
                <b>B:</b> {{ t("all_inclusive_countries_b") }}<br />
                <b>C:</b> {{ t("all_inclusive_countries_c") }}<br />
                <b>D:</b> {{ t("all_inclusive_countries_d") }}
              </div>

              <div class="text-body-2 mb-2 mt-3">
                <b>{{ t("rate_for_russia_and_ukraine") }}:</b>
              </div>

              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>{{ t("size_country") }}</th>
                      <th>{{ t("russia") }}</th>
                      <th>{{ t("ukraine") }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="rate in inclusiveRatesForRussia">
                      <td>{{ rate[0] }}</td>
                      <td>
                        <strong>{{ rate[1] }} RUB</strong>
                      </td>
                      <td>{{ rate[2] }} UAH</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="caption">* {{ t("rate_for_russia_hint") }}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    mixedRates() {
      return [
        ["A", "$7", "40%", "30%"],
        ["B", "$6", "40%", "25%"],
        ["C", "$5", "40%", "25%"],
        ["D", "$4", "40%", "25%"],
        ["E", "$3", "30%", "20%"],
        ["F", "$2", "20%", "15%"],
        ["G", "$1", "20%", "15%"]
      ];
    },
    mixedRatesForRussiaAndUkraine() {
      return [
        [this.t("russia"), "245 RUR", "40%", "30%"],
        [this.t("ukraine"), "48 UAH", "40%", "25%"]
      ];
    },
    inclusiveRates() {
      return [
        ["0.5-5 MB", "$3", "$2", "$1", "$0"],
        ["5-10 MB", "$4", "$3", "$2", "$0.5"],
        ["10-50 MB", "$6", "$4", "$3", "$1"],
        ["50-100 MB", "$8", "$6", "$4", "$1.5"],
        ["100-250 MB", "$10", "$8", "$6", "$2"],
        ["250-1500 MB", "$15", "$13", "$7", "$3"],
        ["1500MB-unlimited", "$20", "$16", "$10", "$5"]
      ];
    },
    inclusiveRatesForRussia() {
      return [
        ["0.5-5 MB", "105", "24"],
        ["5-10 MB", "149", "36"],
        ["10-50 MB", "210", "48"],
        ["50-100 MB", "280", "72"],
        ["100-250 MB", "350", "96"],
        ["250-1500 MB", "525", "156"],
        ["1500 MB-unlimited", "700", "192"]
      ];
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
