<template>
  <v-col :sm="12" :md="12" :lg="10">
    <v-row class="justify-center">
      <v-col v-if="programActivated" :md="10">
        <hitfile-program-settings />
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col :md="10" :cols="12">
        <hitfile-rate-plans />
      </v-col>

      <v-col :md="10" :cols="12">
        <hitfile-for-site-owners />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import HitfileRatePlans from "./HitfileRatePlans";
import HitfileForSiteOwners from "./HitfileForSiteOwners";
import HitfileProgramSettings from "./HitfileProgramSettings";

export default {
  components: { HitfileProgramSettings, HitfileForSiteOwners, HitfileRatePlans },
  computed: {
    programActivated() {
      return this.$store.state.auth.userData.app_users_id.fd2;
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
