<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="pr-4">
          <h5 class="text-18 pb-1">
            {{ t("for") }}
            <router-link :to="{ name: 'sites', params: { app: 'fd2' } }" class="text-decoration-underline">{{
              t("site_owners")
            }}</router-link>
          </h5>
        </div>
        <v-img :src="layoutImg" :max-width="42" :max-height="42" />
      </div>

      <div class="grey--text text--darken-3 text-15">
        <span v-html="t('for_site_owners_line_1', { percents: 15 })" /><br />
        <span v-html="t('for_site_owners_line_2')" /><br />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import LayoutImg from "@/assets/images/layout.svg";

export default {
  computed: {
    layoutImg() {
      return LayoutImg;
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
