<template>
  <v-col :sm="12" :md="12" :lg="10">
    <v-row class="justify-center">
      <v-col v-if="programActivated" :md="10">
        <turbobit-program-settings />
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col :md="10">
        <turbobit-rate-plans />
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col :sm="12" :md="12" :lg="10">
        <div class="text-center">
          <div class="mb-2 mt-2 text-24">{{ t("additional_earnings") }}</div>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="5" :cols="12">
        <turbobit-bonus-program />
      </v-col>

      <v-col :md="5" :cols="12">
        <turbobit-for-site-owners />
      </v-col>

      <v-col :md="10" :cols="12">
        <turbobit-referral-system />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import TurbobitProgramSettings from "./TurbobitProgramSettings";
import TurbobitRatePlans from "./TurbobitRatePlans";
import TurbobitBonusProgram from "./TurbobitBonusProgram";
import TurbobitForSiteOwners from "./TurbobitForSiteOwners";
import TurbobitReferralSystem from "./TurbobitReferralSystem";

export default {
  components: {
    TurbobitReferralSystem,
    TurbobitForSiteOwners,
    TurbobitBonusProgram,
    TurbobitRatePlans,
    TurbobitProgramSettings
  },
  computed: {
    programActivated() {
      return this.$store.state.auth.userData.app_users_id.fd1;
    }
  },
  methods: {
    t(key, params) {
      return this.$parent.t(key, params);
    }
  }
};
</script>
