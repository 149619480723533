<template>
  <div>
    <v-row class="justify-center">
      <v-col :sm="12" :md="12" :lg="10">
        <ca-page-header :title="t('page_title')" icon="mdi-text-box-check-outline">
          {{ t("page_subtitle") }}
        </ca-page-header>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <turbobit-index v-if="apptype === 'fd1'" />
      <hitfile-index v-if="apptype === 'fd2'" />
    </v-row>
  </div>
</template>

<script>
import CaPageHeader from "../../components/PageHeader";
import TurbobitIndex from "./TurbobitIndex";
import HitfileIndex from "./HitfileIndex";

export default {
  components: { HitfileIndex, TurbobitIndex, CaPageHeader },
  metaInfo() {
    return {
      title: this.$t("titles.rates")
    };
  },
  computed: {
    apptype() {
      return this.$route.params.app;
    }
  },
  methods: {
    t(key, params) {
      return this.$t("pages.rates." + key, params);
    }
  }
};
</script>
